const { attachDelegateEvent } = require('./utils');
const HEADER_MAIN = document.getElementById('main-header');
const HEADER_IDENTITY = document.getElementById('header-identity');
const HEADER_NAV_PRIMARY = document.getElementById('header-nav-primary');
const HEADER_NAV_ACCOUNT = document.getElementById('header-nav-account');
const HEADER_SEARCH = document.getElementById('header-search');
const NAV_TOGGLE = document.getElementById('mobile-menu-toggle');
let ALERT_INTERVAL = null;

const removeAlert = function (e) {
    const alert = this.closest('.usa-alert');
    alert.classList.add('dismissed');
    setTimeout(() => {
        alert.remove();
    }, 1000);
};

const toggleAlertsDisplay = () => {
    try {
        const alertsContainer = document.getElementById('alerts-container');
        if (alertsContainer.querySelectorAll('.usa-alert').length > 0) {
            document.body.classList.add('has-alerts');
            if (window.getComputedStyle(HEADER_MAIN).position !== 'relative') {
                alertsContainer.style.top = window.getComputedStyle(HEADER_MAIN).height;
            }
        } else {
            document.body.classList.remove('has-alerts');
        }
    } catch (err) {
        console.error(err);
        clearInterval(ALERT_INTERVAL);
    }
}; // toggleAlertsDisplay

const toggleNavOpen = (e) => {
    document.body.classList.toggle('nav-open');
}; // toggleNavOpen

const moveAccountNavigation = (e) => {
    if (document.body.classList.contains('logged_in')) {
        if (window.innerWidth >= 768) {
            HEADER_IDENTITY.parentNode.appendChild(HEADER_NAV_ACCOUNT);
        } else {
            HEADER_SEARCH.parentNode.insertBefore(HEADER_NAV_ACCOUNT, HEADER_SEARCH);
        }
    }
}; // moveAccountNavigation

const initializeHeader = () => {
    if (NAV_TOGGLE) {
        NAV_TOGGLE.addEventListener('click', toggleNavOpen);
    }
    ALERT_INTERVAL = setInterval(toggleAlertsDisplay, 250);
    window.addEventListener('resize', moveAccountNavigation);
    window.dispatchEvent(new Event('resize'));
    attachDelegateEvent('click', '[data-remove-alert]', removeAlert);
}; // initializeHeader

module.exports = {
    initializeHeader,
};
