const ProfileDetail = {
    truncateNaicsList: () => {
        const displayLimit = 17;
        const companyNaics = Array.from(document.querySelectorAll('[data-naics-type]'));
        const moreElem = Object.assign(document.createElement('button'), {
            id: 'more-naics',
            className: 'button button--unstyled',
            innerText: `View ${companyNaics.length - displayLimit} more`,
        });

        moreElem.onclick = showNaics;

        function showNaics() {
            companyNaics.forEach(function (n) {
                n.closest('.usa-tooltip').removeAttribute('hidden');
            });
            document.getElementById('more-naics').toggleAttribute('hidden');
        }

        if (companyNaics.length > displayLimit) {
            companyNaics.slice(displayLimit).forEach(function (n) {
                n.closest('.usa-tooltip').toggleAttribute('hidden');
            });
            document.getElementById('entity-detail-tags').appendChild(moreElem);
        }
    }, // truncateNaicsList

    init: () => {
        ProfileDetail.truncateNaicsList();
    }, // init
}; // ProfileDetail

module.exports = {
    ProfileDetail,
};
