const initializeTabs = () => {
    const tabbed = document.querySelector('.tabbed');
    if (tabbed != null && typeof tabbed != 'undefined') {
        const tablist = tabbed.querySelector('ul');
        const tabs = tablist.querySelectorAll('a');
        const panels = document.querySelectorAll('[id^="tab-"],[id^="section"]');
        const switchTab = (oldTab, newTab) => {
            if (!oldTab) {
                oldTab = tablist.querySelector('[aria-selected]');
            }
            if (oldTab == newTab) {
                return;
            }
            newTab.focus();
            newTab.removeAttribute('tabindex');
            newTab.setAttribute('aria-selected', 'true');
            oldTab.removeAttribute('aria-selected');
            oldTab.setAttribute('tabindex', '-1');
            location.hash = newTab.hash.replace(/#/, '#/');
            let index = Array.prototype.indexOf.call(tabs, newTab);
            let oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
            panels[oldIndex].hidden = true;
            panels[index].hidden = false;
            newTab.dispatchEvent(new Event('click'));
        };
        tablist.setAttribute('role', 'tablist');
        Array.prototype.forEach.call(tabs, (tab, i) => {
            tab.setAttribute('role', 'tab');
            tab.setAttribute('id', 'tab' + (i + 1));
            tab.setAttribute('tabindex', '-1');
            tab.parentNode.setAttribute('role', 'presentation');
            tab.addEventListener('click', (e) => {
                e.preventDefault();
                let currentTab = tablist.querySelector('[aria-selected]');
                if (e.currentTarget !== currentTab) {
                    switchTab(currentTab, e.currentTarget, true);
                }
            });
            tab.addEventListener('keydown', (e) => {
                let index = Array.prototype.indexOf.call(tabs, e.currentTarget);
                let dir =
                    e.which === 37
                        ? index - 1
                        : e.which === 39
                          ? index + 1
                          : e.which === 40
                            ? 'down'
                            : null;
                if (dir !== null) {
                    e.preventDefault();
                    dir === 'down'
                        ? panels[i].focus()
                        : tabs[dir]
                          ? switchTab(e.currentTarget, tabs[dir], true)
                          : void 0;
                }
            });
            tab.setAsActive = () => {
                switchTab(null, tab, false);
            };
        });
        Array.prototype.forEach.call(panels, (panel, i) => {
            panel.setAttribute('role', 'tabpanel');
            panel.setAttribute('tabindex', '-1');
            let id = panel.getAttribute('id');
            panel.setAttribute('aria-labelledby', tabs[i].id);
            panel.hidden = true;
        });
        tabs[0].removeAttribute('tabindex');
        tabs[0].setAttribute('aria-selected', 'true');
        panels[0].hidden = false;
        const loadHash = location.hash.replace(/[#/]*/g, '');
        let selectedTabContent = document.getElementById(loadHash);
        if (selectedTabContent) {
            let selectedTab = document.getElementById(
                selectedTabContent.getAttribute('aria-labelledby')
            );
            if (selectedTab != tabs[0]) {
                switchTab(tabs[0], selectedTab, true);
                selectedTab.blur();
            }
        }
    }
}; // initializeTabs

module.exports = {
    initializeTabs,
};
