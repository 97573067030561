const { alphaSort, eventEmitter, getData, getSiblings, newEl, removeElems } = require('./utils');

const URL_PARAMS = new URLSearchParams(location.search.substring(1));
const MS = 'multiselect';
const MS_DROPDOWN = `${MS}-dropdown`,
    MS_OPTION = `${MS}-option`,
    MS_OPEN = `${MS}--open`,
    MS_CLOSED = `${MS}--closed`;

function onDocumentClick(e) {
    var msDropdown = e.target.closest(`.${MS_DROPDOWN}`);
    if (!msDropdown) {
        msDropdown = document.querySelector(`.${MS_OPEN}`);
        if (msDropdown) {
            msDropdown.dispatchEvent(eventEmitter('ms:close', this, e));
        }
    }
} // onDocumentClick

function makeInputField(select) {
    const input = newEl(
        'input',
        {
            className: [`${MS_DROPDOWN}-search`],
            placeholder: select.getAttribute('placeholder'),
            disabled: true,
        },
        {
            'aria-controls': select.id,
            'data-placeholder': select.getAttribute('placeholder'),
        }
    );
    ['labelledby', 'describedby'].forEach((aria) => {
        if (select.hasAttribute(`aria-${aria}`)) {
            input.setAttribute(`aria-${aria}`, select.getAttribute(`aria-${aria}`));
        }
    });
    return input;
} // makeInputField

// MultiSelectFactory
const MultiSelectFactory = function (select, config) {
    console.time(`MultiSelectFactory: ${select.id}`);

    if (!document.hasMultiSelects) {
        document.hasMultiSelects = true;
        document.addEventListener('click', onDocumentClick);
    }
    if (select.multiselect) {
        removeElems([select.multiselect.rootDiv]);
        select.multiselect = null;
    }
    select.classList.add(`${MS}--hidden`);

    /*
    msObject: object that holds everything that the select does not. accessible via select.multiselect
     - methods:
        init
        reset
    */
    const msObject = {
        init: async () => {
            if (select.getAttribute('ms-source') === 'remote') {
                await getData(select.name, select.getAttribute('ms-params'), loadOptions, ready);
            } else {
                loadOptions({
                    data: Array.from(select).map((opt) => {
                        return { value: opt.value, label: opt.innerHTML };
                    }),
                });
                ready();
            }
        }, // init

        reset: (defer) => {
            Array.from(select.selectedOptions).forEach((o) => o.listItem.click());
            if (!defer) {
                select.dispatchEvent(new Event('change'));
            }
        }, // reset

        // options
        display: select.getAttribute('ms-display'),

        // elems
        select: select, // not actually necessary
        rootDiv: newEl('div', {
            className: [MS_DROPDOWN, 'text-input'],
        }),
        listWrapper: newEl('div', {
            className: `${MS_DROPDOWN}-list-wrapper`,
        }),
        list: newEl('ul', {
            className: `${MS_DROPDOWN}-list`,
        }),
        search: makeInputField(select),
        selectTarget: document.querySelector(select.getAttribute('ms-select-target')),
    }; // msObject

    // validate the select has everything it needs
    Object.keys(msObject).forEach((k) => {
        if (!msObject[k]) {
            alert('There seems to be a problem loading this form. Please refresh and try again.');
        }
    });

    const eventHandlers = {
        onListItemClick: function (e) {
            const eventType = this.classList.contains('checked') ? 'deselect' : 'select';
            const optionValue = this.data[select.getAttribute('ms-value')];
            const optionSelector = `option[value="${optionValue}"]`;
            e.preventDefault();
            if (eventType === 'select') {
                this.classList.add('checked');
                this.querySelector('input').checked = true;
                if (!select.querySelector(optionSelector)) {
                    var option = newEl('option', {
                        value: optionValue,
                        innerHTML: msObject.display.interpolate(this.data),
                    });
                    Object.assign(option, {
                        data: this.data,
                        listItem: this,
                        selected: true,
                    });
                    select.appendChild(option);
                }
            } else {
                this.classList.remove('checked');
                this.querySelector('input').checked = false;
                removeElems(select.querySelectorAll(optionSelector));
            }
            this.dispatchEvent.call(select, eventEmitter(`ms:${eventType}`, this, e));
        }, // onListItemClick

        onMouseOver: function (e) {
            this.classList.add('highlight');
            getSiblings(this).forEach((sib) => sib.classList.remove('highlight', 'last-highlight'));
        }, // onMouseOver

        onMSChange: function (e) {
            select.dispatchEvent(new CustomEvent('ms:close'));
        }, // onMSChange

        onMSClick: function (e) {
            this.dispatchEvent.call(this, eventEmitter('ms:open', this, e));
        }, // onMSClick

        onMSClose: function (e) {
            e.preventDefault();
            e.cancelBubble = true;
            this.classList.add(MS_CLOSED);
            this.classList.remove(MS_OPEN);
            msObject.list
                .querySelectorAll('.hide')
                .forEach((li) => li.classList.remove('hide', 'highlight', 'last-highlight'));
            msObject.search.value = '';
            showSelections();
            switch (e.type) {
                case 'ms:close':
                    select.dispatchEvent(new Event('change'));
                    break;
            }
        }, // onMSClose

        onMSKeydown: function (evt) {
            var list = evt.detail.originalTarget,
                target = this,
                e = evt.detail.originalEvent,
                currentElem = list.querySelector('.highlight:not(.hide), .last-highlight:not(.hide)'),
                nextElem,
                previousElem;

            if (currentElem) {
                (nextElem = currentElem.nextSibling), (previousElem = currentElem.previousSibling);
            }

            switch (e.code) {
                case 'ArrowDown':
                    if (currentElem) {
                        if (nextElem) {
                            if (list.scrollTop + list.offsetHeight < nextElem.offsetTop) {
                                list.scrollBy(0, currentElem.offsetHeight);
                            }
                            nextElem.dispatchEvent(new Event('mouseover'));
                        }
                    } else {
                        currentElem = list.querySelector(`.${MS_OPTION}:not(.hide)`);
                        currentElem.dispatchEvent(new Event('mouseover'));
                    }
                    return false;
                case 'ArrowUp':
                    if (currentElem && previousElem) {
                        if (list.scrollTop + currentElem.offsetHeight < previousElem.offsetTop) {
                            list.scrollBy(0, -currentElem.offsetHeight);
                        }
                        previousElem.dispatchEvent(new Event('mouseover'));
                    }
                    return false;
                case 'Escape':
                case 'Tab':
                    e.preventDefault();
                    e.cancelBubble = true;
                    if (target.value === '') {
                        msObject.rootDiv.dispatchEvent(new CustomEvent('ms:close'));
                    } else {
                        target.value = '';
                        target.dispatchEvent(new Event('input'));
                    }
                    target.blur();
                    return false;

                case 'Enter':
                case 'NumpadEnter':
                    e.preventDefault();
                    e.cancelBubble = true;
                    if (currentElem) {
                        currentElem.dispatchEvent(new Event('click'));
                        currentElem.classList.add('last-highlight');
                        target.value = '';
                        target.dispatchEvent(new Event('input'));
                        return false;
                    } else {
                        if (target.value.indexOf(',') >= 1) {
                            const vals = target.value.split(',').map((x) => x.trim());
                            vals.forEach((v) => {
                                list.querySelectorAll(`[data-code="${v}"]:not(.checked)`).forEach(
                                    (li) => {
                                        li.dispatchEvent(new Event('click'));
                                    }
                                );
                            });
                            target.value = '';
                            msObject.rootDiv.dispatchEvent(new CustomEvent('ms:close'));
                        }
                    }
                    break;
            } // switch
        }, // onMSKeydown

        onMSOpen: function (e) {
            e.preventDefault();
            e.cancelBubble = true;
            document.querySelectorAll(`.${MS_OPEN}`).forEach((ms) => {
                if (ms !== this) {
                    ms.classList.add(MS_CLOSED);
                    ms.classList.remove(MS_OPEN);
                }
            });
            msObject.rootDiv.classList.add(MS_OPEN);
            msObject.rootDiv.classList.remove(MS_CLOSED);
            msObject.search.focus();
            msObject.search.select();
        }, // onMSOpen

        onMSSelect: function (e) {
            select.dispatchEvent(new Event('change'));
        }, // onMSSelect

        onMSDeselect: function (e) {
            select.dispatchEvent(new Event('change'));
        }, // onMSDeselect

        onProxyRemove: function (e) {
            e.cancelBubble = true;
            this.listItem.click();
            msObject.rootDiv.dispatchEvent(new CustomEvent('ms:close'));
            document.activeElement.blur();
        }, // onProxyRemove

        onSearchInput: function (e) {
            e.stopPropagation();
            e.preventDefault();
            e.cancelBubble = true;
            msObject.list.querySelectorAll(':scope li').forEach((li) => {
                if (li.searchText.includes(this.value.toUpperCase())) {
                    li.classList.add('show');
                    li.classList.remove('hide');
                } else {
                    li.classList.remove('show');
                    li.classList.add('hide');
                }
            });
        }, // onSearchInput

        onSearchKeydown: function (e) {
            switch (e.code) {
                case 'ArrowDown':
                case 'ArrowUp':
                case 'Enter':
                case 'Escape':
                case 'NumpadEnter':
                case 'Tab':
                    e.preventDefault();
                    e.cancelBubble = true;
                    msObject.rootDiv.dispatchEvent(new CustomEvent('ms:open'));
                    this.dispatchEvent(eventEmitter('ms:keydown', msObject.list, e));
                    break;
            }
        }, //onSearchKeydown

        onSelectionsUpdated: function (e) {
            updatePlaceholder();
            const selectedFilters = Array.from(
                this.querySelectorAll('.multiselect--selected-filters--group[data-group]')
            );
            selectedFilters.sort((a, b) => alphaSort(a, b, 'data-group', true)).reverse(); // reverse, because we happen to put them in reverse order
            selectedFilters.forEach((filter) => this.appendChild(filter));
        }, // onSelectionsUpdated
    };

    const makeListItem = (item) => {
        const valueProp = select.getAttribute('ms-value');
        const listItem = newEl(
            'li',
            {
                className: [MS_OPTION],
                data: item,
                role: 'option',
                innerHTML: `<label><input type="checkbox" class="${MS}-checkbox" value="${item[valueProp]}" /><span>${msObject.display.interpolate(item)}</span></label>`,
            },
            {
                'aria-select': select.id,
                'data-code': item[valueProp],
            }
        );
        listItem.searchText = Object.values(item).join(' ').toUpperCase();
        listItem.addEventListener('click', eventHandlers.onListItemClick);
        listItem.addEventListener('ms:select', eventHandlers.onMSSelect);
        listItem.addEventListener('mouseover', eventHandlers.onMouseOver);
        return listItem;
    }; // makeListItem

    const loadOptions = (response) => {
        const data = !!response.data.results ? response.data.results : response.data;
        msObject.data = data;
        removeElems(select.getElementsByTagName('option'));
        data.forEach((item) => msObject.list.appendChild(makeListItem(item)));
        URL_PARAMS.getAll(select.name).forEach((v) => {
            document.querySelector(`[data-code="${v}"]`).click();
        });
    }; // loadOptions

    const ready = () => {
        select.style.display = 'none';
        select.parentNode.appendChild(msObject.rootDiv);
        msObject.rootDiv.dispatchEvent(eventEmitter('ms:ready', msObject.rootDiv, { from: 'ready' }));
        msObject.search.removeAttribute('disabled');
        select.setAttribute('ms-search', 'ready');
    }; // ready

    const updatePlaceholder = () => {
        if (select.selectedOptions.length > 0) {
            msObject.search.placeholder = `${select.selectedOptions.length} selected`;
        } else {
            msObject.search.placeholder = msObject.search.getAttribute('data-placeholder');
        }
    }; // updatePlaceholder

    const showSelections = (e) => {
        if (!msObject.selectTarget) {
            console.log('huh?');
            return;
        }
        const selectedOptions = Array.from(select.selectedOptions);
        let selectedOptionGroup = null;
        removeElems(
            msObject.selectTarget.querySelectorAll(
                `[data-group="${select.getAttribute('ms-group')}"], [data-group="${select.getAttribute('ms-group')}"] li`
            )
        );
        if (selectedOptions.length > 0) {
            selectedOptionGroup = newEl(
                'ul',
                {
                    className: ['multiselect--selected-filters--group'],
                },
                {
                    'data-group': select.getAttribute('ms-group'),
                }
            );

            selectedOptions.forEach((option) => {
                let selectedOption = newEl(
                    'li',
                    {
                        className: `${MS_OPTION}--selected`,
                        innerHTML: select.hasAttribute('ms-label')
                            ? option.data[select.getAttribute('ms-label')]
                            : option.text,
                        title: option.text,
                    },
                    {
                        'aria-controls': select.id,
                    }
                );
                const removeButton = newEl('span', {
                    className: [`${MS_OPTION}--remove`],
                    innerHTML: 'x',
                    title: 'Remove this selection',
                });
                selectedOption.listItem = option.listItem;
                selectedOption.addEventListener('click', eventHandlers.onProxyRemove);
                selectedOption.appendChild(removeButton);
                selectedOptionGroup.appendChild(selectedOption);
            }); // for
            msObject.selectTarget.appendChild(selectedOptionGroup);
        }
        msObject.selectTarget.dispatchEvent(eventEmitter('ms:selections', msObject.selectTarget));
    }; // showSelections

    msObject.rootDiv.addEventListener('ms:open', eventHandlers.onMSOpen);
    msObject.rootDiv.addEventListener('ms:close', eventHandlers.onMSClose);
    msObject.rootDiv.addEventListener('ms:ready', eventHandlers.onMSClose);
    msObject.rootDiv.addEventListener('click', eventHandlers.onMSClick);
    msObject.search.addEventListener('keydown', eventHandlers.onSearchKeydown);
    msObject.search.addEventListener('ms:keydown', eventHandlers.onMSKeydown);
    msObject.search.addEventListener('input', eventHandlers.onSearchInput);
    msObject.selectTarget.addEventListener('ms:selections', eventHandlers.onSelectionsUpdated);
    select.addEventListener('change', eventHandlers.onMSChange);

    select.multiselect = msObject;
    msObject.rootDiv.appendChild(msObject.search);
    msObject.listWrapper.appendChild(msObject.list);
    msObject.rootDiv.appendChild(msObject.listWrapper);
    select.parentNode.appendChild(msObject.rootDiv);

    console.timeEnd(`MultiSelectFactory: ${select.id}`);
    return msObject;
}; // MultiSelectFactory

module.exports = {
    MultiSelectFactory,
};
