const { newEl } = require('./utils');

const keyBindings = {
    '/': {
        target: Array.from(document.querySelectorAll('[type="search"]')).at(-1),
        domain: 'global',
        help: 'Search for an entity',
    },
    ']': {
        target: '[aria-label="Next page"]',
        domain: 'search',
        help: 'Go to the next page of search results',
    },
    '[': {
        target: '[aria-label="Previous page"]',
        domain: 'search',
        help: 'Go to the previous page of search results',
    },
    s: {
        target: '[aria-controls="id_business_types"]',
        domain: 'search',
        help: 'Open the Socioeconomic Status field',
    },
    n: {
        target: '[aria-controls="id_naics"]',
        domain: 'search',
        help: 'Open the NAICS field',
    },
    p: {
        target: '[aria-controls="id_psc"]',
        domain: 'search',
        help: 'Open the PSC field',
    },
    d: {
        target: '[aria-controls="id_departments"]',
        domain: 'search',
        help: 'Open the Departments field',
    },
    a: {
        target: '[aria-controls="id_agencies"]',
        domain: 'search',
        help: 'Open the Agencies field',
    },
    'shift+r': {
        target: '#reset-button',
        domain: 'search',
        help: 'Clear the search form',
    },
    'shift+enter': {
        target: '#filter-button',
        domain: 'search',
        help: 'Execute the search',
    },
    '?': {
        target: '[aria-controls="modal-help"]',
        domain: 'global',
        help: 'Open keyboard shortcut help',
    },
    'up up down down left right left right b a enter': {
        target: null,
        domain: 'global',
        help: null,
    },
}; // keyBindings

// add dynamic
Array.from(document.querySelectorAll('[data-key]')).forEach((elem) => {
    let k = elem.getAttribute('data-key');
    keyBindings[k] = {
        target: elem,
        domain: 'global',
        help: elem.getAttribute('data-help'),
    };
});

const handleKeyStroke = (e, sequence, x) => {
    if (e.preventDefault) {
        e.preventDefault();
    } else {
        // internet explorer
        e.returnValue = false;
    }
    let key = sequence || e.key;
    let btn = keyBindings[key].target;
    if (
        keyBindings[key].domain == 'global' ||
        document.body.classList.contains(keyBindings[key].domain)
    ) {
        switch (typeof btn) {
            case 'string':
                btn = document.querySelector(btn);
                break;
        }
        if (btn && btn.tagName) {
            switch (e.key) {
                case '/':
                    btn.focus();
                    break;
                default:
                    btn.click();
                    break;
            }
        }
        return false;
    }
}; // handleKeyStroke

const generateHelpForDomain = (domain) => {
    const HELP_ITEM = '<span class="key">${keys}</span><span class="help-text">${help}</span>';
    const ul = newEl('ul', {
        className: 'list-keystrokes',
    });
    ul.appendChild(
        newEl('li', {
            className: 'help-item help-item--header',
            innerHTML: HELP_ITEM.interpolate({ keys: '', help: domain.toTitleCase() }),
        })
    );
    Object.keys(keyBindings)
        .filter((d) => keyBindings[d].domain == domain)
        .forEach((k) => {
            const v = keyBindings[k];
            const key = k
                .replace(/([a-z]{2,})/g, (m) => {
                    return m.toTitleCase();
                })
                .replace(/ /, 'then')
                .replace(/(\+|then)/, '<span class="oper">$1</span>');
            if (v.help) {
                ul.appendChild(
                    newEl('li', {
                        className: 'help-item',
                        innerHTML: HELP_ITEM.interpolate({ keys: key, help: v.help }),
                    })
                );
            }
        });
    return ul;
}; // generateHelpForDomain

const generateHelpModal = () => {
    const helpModal = document.getElementById('modal-help-content');
    if (helpModal) {
        helpModal.appendChild(generateHelpForDomain('global'));
        helpModal.appendChild(generateHelpForDomain('search'));

        // make 'em look nice
        const keys = Array.from(helpModal.querySelectorAll('.key'));
        const maxWidth = Math.max(...keys.map((k) => k.offsetWidth)) + 20;
        keys.forEach((k) => {
            k.style.width = `${maxWidth}px`;
        });
    }
}; // generateHelpMenu

module.exports = {
    generateHelpModal,
    handleKeyStroke,
    keyBindings,
};
