const htmx = require('htmx.org');

const toggleModal = (modal) => {
    if (window.debug) {
        console.log('toggleModal', modal.id);
    }
    if (modal) {
        if (window.debug) {
            console.log(modal.id, modal);
        }
        if (modal.classList.contains('is-hidden')) {
            modal.classList.remove('is-hidden');
            modal.classList.add('is-visible');
        } else {
            modal.classList.add('is-hidden');
            modal.classList.remove('is-visible');
            modal.querySelector('.modal-dialog').innerHTML = '';
        }
    }
}; // toggleModal

htmx.on('htmx:beforeSwap', (e) => {
    // Modals
    if (window.debug) {
        console.log(e.type, e);
    }
    if (e.detail.target && e.detail.target.classList.contains('modal-dialog')) {
        const modal = e.detail.target.closest('.usa-modal-wrapper');
        if (modal) {
            try {
                modal.classList.add('is-hidden');
                modal.classList.remove('is-visible');
            } catch (err) {
                console.log(err);
                console.log(e.detail.target);
            }
        }
    }
});

htmx.on('htmx:afterSwap', (e) => {
    // Modals
    if (window.debug) {
        console.log(e.type, e);
    }
    if (e.detail.target && e.detail.target.classList.contains('modal-dialog')) {
        const modal = e.detail.target.closest('.usa-modal-wrapper');
        if (modal) {
            if (!modal.querySelector('[data-preload]')) {
                toggleModal(modal);
            }
            if (e.detail.xhr.response) {
                e.detail.shouldSwap = true;
                modal.querySelector('[data-close-modal]').addEventListener('click', () => {
                    toggleModal(modal);
                });
            }
            document.body.dispatchEvent(new CustomEvent('modal:loaded', { detail: { id: modal.id } }));
        } else {
            console.error("72.1 Error Uh-oh. We've got a bit of a problem.", e.detail); // no modal
        }
    }
});

htmx.on('htmx:afterRequest', (e) => {
    // Flash Messages
    if (window.debug) {
        console.log(e.type, e);
    }
});

module.exports = {
    toggleModal,
};
