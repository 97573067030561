const { MultiSelectFactory } = require('./multiselect');

function onInputBlur(e) {
    this.value = this.value.trim();
}

const TandemSearch = {
    multiselects: {},
    changeCheck: '',
    form: null,
    waitForReady: null,

    onFormReset: function (e) {
        TandemSearch.form.elements.search.value = '';
        document.getElementById('global-search').value = '';
        const multiselects = Object.values(TandemSearch.multiselects);
        multiselects.forEach((multiselect) => {
            if (multiselect.select.value) {
                multiselect.reset(); // pass true to defer, but is this working?
            }
        });
        // multiselects.at(-1).reset(); // only if deferred
        TandemSearch.form.elements.search.focus();
    }, // onFormReset

    onFormSubmit: function (e) {
        document.getElementById('id_page_number').value = '1'; // reset the value if an actual search is run
        var entries = Array.from(new FormData(TandemSearch.form).entries()).toString();
        if (entries === TandemSearch.changeCheck) {
            e.cancelBubble = true;
            e.preventDefault();
            return false;
        }
        TandemSearch.changeCheck = entries;
    }, // onFormSubmit

    onNewResults: function (e) {
        this.setAttribute('data-query', TandemSearch.changeCheck);
        this.closest('[role="main"]').scrollTo(0, 0);
    }, // onNewResults

    localSubmit: (e) => {
        TandemSearch.submitButton.click();
    }, // localSubmit

    init: () => {
        const formId = document.body.getAttribute('data-form') || 'research-form';
        TandemSearch.form = document.getElementById(formId);
        TandemSearch.submitButton = TandemSearch.form.querySelector('[type="submit"]');
        TandemSearch.resultsDiv = document.getElementById(
            TandemSearch.form.getAttribute('data-results')
        );

        const multiSelects = Array.from(TandemSearch.form.querySelectorAll('[ms-search]'));

        // submits the form after it is done loading for the first time
        TandemSearch.waitForReady = setInterval(() => {
            let readySelects = multiSelects.filter(
                (select) => select.getAttribute('ms-search') === 'ready'
            );
            if (readySelects.length > 0 && readySelects.length === multiSelects.length) {
                clearInterval(TandemSearch.waitForReady);
                TandemSearch.localSubmit();
            }
        }, 100);

        // add handler for non-multiselects
        TandemSearch.form.querySelectorAll('input:not([class*="multiselect"])').forEach((input) => {
            switch (input.type) {
                case 'checkbox':
                case 'radio':
                    input.addEventListener('change', TandemSearch.localSubmit);
                    break;
                case 'text':
                    input.addEventListener('blur', onInputBlur);
                    input.dispatchEvent(new Event('blur'));
                    break;
            }
        });

        // initialize the multiselects
        multiSelects.forEach((select) => {
            TandemSearch.multiselects[select.name] = MultiSelectFactory(select);
            TandemSearch.multiselects[select.name].init();
            select.addEventListener('change', TandemSearch.localSubmit);
        });
        TandemSearch.form.addEventListener('htmx:beforeRequest', TandemSearch.onFormSubmit);
        document.getElementById('reset-button').addEventListener('click', TandemSearch.onFormReset);
        TandemSearch.resultsDiv.addEventListener('htmx:afterSwap', TandemSearch.onNewResults);
    }, // init
};

module.exports = {
    TandemSearch,
};
