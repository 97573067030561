const axios = require('axios');

// String enhancements
String.prototype.interpolate = function (params) {
    const names = Object.keys(params);
    const vals = Object.values(params);
    return new Function(...names, `return \`${this}\`;`)(...vals);
}; // worth it

String.prototype.toTitleCase = function (str) {
    return this.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
}; // also worth it

// Helper functions
const alphaSort = (a, b, attr) => {
    const nameA = a.getAttribute(attr).toUpperCase(); // ignore upper and lowercase
    const nameB = b.getAttribute(attr).toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    // names must be equal
    return 0;
}; // alphaSort

const apiUrl = (obj, params) => {
    const qs = params ? `?${params}` : '';
    return `/api/${obj}/${qs}`;
}; // apiUrl

const attachDelegateEvent = (eventType, selector, handler) => {
    document.body.addEventListener(eventType, (e) => {
        var target = e.target.closest(selector);
        if (target) {
            e.stopPropagation();
            handler.call(target, e);
        }
    });
}; // attachDelegateEvent

const eventEmitter = (type, elem, evt, extra) => {
    return new CustomEvent(type, {
        bubbles: false,
        cancelable: true,
        cancelBubble: true,
        defaultPrevented: true,
        detail: Object.assign(
            {
                originalTarget: elem,
                originalEvent: evt,
            },
            extra
        ),
    });
}; // eventEmitter

const getData = async (obj, params, callback, final) => {
    final = final ? final : () => console.log('finally');
    axios
        .get(apiUrl(obj, params))
        .then(callback)
        .catch(function (err) {
            console.log(err);
        })
        .finally(final);
}; // getData

const getSiblings = (elem) => {
    return Array.from(elem.parentNode.childNodes).filter((node) => {
        return node !== elem;
    });
}; // getSiblings

const isMobileBrowser = () => {
    return /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
}; // isMobileBrowser

const newEl = (tag, attrs, customAttrs) => {
    attrs = attrs ? attrs : {};
    if (Array.isArray(attrs['className'])) {
        attrs['className'] = attrs['className'].join(' ');
    }
    var elem = Object.assign(document.createElement(tag), attrs);
    if (customAttrs) {
        Object.keys(customAttrs).forEach((k) => {
            elem.setAttribute(k, customAttrs[k]);
        });
    }
    return elem;
}; // newEl

const removeElems = (elems) => {
    Array.from(elems).forEach((elem) => elem.remove());
}; // removeElems

const resizeMainContent = () => {
    if (!document.body.classList.contains('app')) {
        const mc = document.getElementById('main-content'),
            mh = document.getElementById('main-header'),
            mf = document.getElementById('main-footer');

        mc.style.height = `calc(100vh - ${mh.offsetHeight + mf.offsetHeight}px)`;
        mc.classList.add('sized');
    }
}; // resizeMainContent

module.exports = {
    alphaSort,
    apiUrl,
    attachDelegateEvent,
    eventEmitter,
    getData,
    getSiblings,
    isMobileBrowser,
    newEl,
    removeElems,
    resizeMainContent,
};
